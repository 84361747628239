import axios from "axios";
import moment from "moment";

export default class BookingAvailability {
  static fetchData(): any {
    return axios
      .get(
        "https://sheets.googleapis.com/v4/spreadsheets/1Uv7TNybpIVqAzN98vQT26ZAAb2dhSULhs3-stTNOBCo/values/data!A:B?key=AIzaSyBtuFz87OywKsl2MjGa7ArapIxUi6148JA"
      )
      .then((response) => {
        response.data.values.shift();
        return response.data.values.map((item: any) => {
          return {
            date: moment(item[0]).format("YYYY-MM-DD"),
            status: item[1].toLowerCase(),
          };
        });
      });
  }
}
