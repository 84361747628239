



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PriceListItemInfo extends Vue {
  @Prop() prefix: string;
}
