


















































































































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import BookingAvailability from "@/service/BookingAvailability";
import moment from "moment";
import Reservation from "@/service/Reservation";

@Component
export default class ReservationForm extends Vue {
  @Ref() readonly observerRef!: InstanceType<typeof ValidationObserver>;

  formDateMenu = false;
  checkbox = null;
  formName = "";
  formEmail = "";
  formPhone = "";
  formGuests = 1;
  formMessage = "";
  formDate: any = null;

  buttonLoading = false;
  error = false;
  done = false;

  calendarData: any[] = [];
  errorMessage = "";

  async created(): Promise<void> {
    this.calendarData = await BookingAvailability.fetchData();
  }

  get reservationStartDate(): string {
    return this.formDate ? moment(this.formDate.start).format("LL") : "";
  }

  get reservationEndDate(): string {
    return this.formDate ? moment(this.formDate.end).format("LL") : "";
  }

  get disabledDates(): any[] {
    return this.calendarData
      .filter((item) => item.status !== "available")
      .map((item) => {
        return { start: item.date, end: item.date };
      });
  }

  get calendarAttrs(): any[] {
    const attrs: any[] = [];

    this.calendarData
      .filter((item) => item.status === "reserved")
      .forEach((item) => {
        attrs.push({
          key: item.date,
          highlight: { class: "n-reserved-day", contentClass: "white--text" },
          dates: item.date,
        });
      });

    this.calendarData
      .filter((item) => item.status === "pending")
      .forEach((item) => {
        attrs.push({
          key: item.date,
          highlight: { class: "n-pending-day" },
          dates: item.date,
        });
      });

    return attrs;
  }

  submit(): void {
    this.errorMessage = "";
    const nights = moment(this.formDate.end).diff(this.formDate.start, "days");
    if (nights < 2) {
      this.errorMessage = this.$t("reservation_form.error_nights").toString();
    } else {
      this.buttonLoading = true;
      Reservation.send({
        name: this.formName,
        email: this.formEmail,
        phone: this.formPhone,
        guests: this.formGuests,
        start: moment(this.formDate.start).format("L"),
        end: moment(this.formDate.end).format("L"),
        message: this.formMessage,
      })
        .then(() => {
          this.done = true;
        })
        .catch(() => {
          this.error = true;
        });
    }
  }
}
