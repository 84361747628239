














































































import { Component, Vue } from "vue-property-decorator";
import PriceListItemInfo from "@/components/PriceListItemInfo.vue";
@Component({
  components: { PriceListItemInfo },
})
export default class PriceList extends Vue {}
