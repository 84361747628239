






































import { Component, Vue } from "vue-property-decorator";
import axios from "axios";
import moment from "moment";

@Component
export default class BookingCalendar extends Vue {
  loading = true;
  tracked: any[] = [];
  focus = "";
  isMounted = false;

  created(): void {
    axios
      .get(
        "https://sheets.googleapis.com/v4/spreadsheets/1Uv7TNybpIVqAzN98vQT26ZAAb2dhSULhs3-stTNOBCo/values/data!A:B?key=AIzaSyBtuFz87OywKsl2MjGa7ArapIxUi6148JA"
      )
      .then((response) => {
        response.data.values.shift();
        this.tracked = response.data.values.map((item: any) => {
          return {
            date: moment(item[0]).format("YYYY-MM-DD"),
            status: item[1].toLowerCase(),
          };
        });
      })
      .finally(() => {
        this.loading = false;
      });
  }

  mounted(): void {
    this.$nextTick(() => {
      this.isMounted = true;
    });
  }

  getDate(date: string): any {
    return this.tracked.find((item) => item.date === date);
  }

  getDateColor(date: string): any {
    const dateDetail = this.getDate(date);
    if (dateDetail) {
      switch (dateDetail.status) {
        case "available":
          return "green";
        case "pending":
          return "yellow";
        case "reserved":
          return "red";
      }
    }
    return "rgba(231,231,231,0.8)";
  }
}
