var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"n-form text-center pa-4"},[(_vm.done)?_c('div',{staticClass:"py-8"},[_c('h3',{staticClass:"headline success--text pb-8",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('reservation_form.thanks_title')))}}),_c('div',{staticClass:"title success--text",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('reservation_form.thanks_text')))}})]):(_vm.error)?_c('div',{staticClass:"py-8"},[_c('h3',{staticClass:"title error--text",domProps:{"innerHTML":_vm._s(_vm.formatText(_vm.$t('reservation_form.error_text')))}})]):_c('div',[_c('validation-observer',{ref:"observerRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-col',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"name":_vm.$t('reservation_form.name'),"rules":"required|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('reservation_form.name'),"required":"","size":"200"},model:{value:(_vm.formName),callback:function ($$v) {_vm.formName=$$v},expression:"formName"}})]}}],null,true)})],1),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"name":_vm.$t('reservation_form.email'),"rules":"required|email|max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":_vm.$t('reservation_form.email'),"type":"email"},model:{value:(_vm.formEmail),callback:function ($$v) {_vm.formEmail=$$v},expression:"formEmail"}})]}}],null,true)})],1),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"name":_vm.$t('reservation_form.phone'),"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","error-messages":errors,"label":_vm.$t('reservation_form.phone'),"type":"phone"},model:{value:(_vm.formPhone),callback:function ($$v) {_vm.formPhone=$$v},expression:"formPhone"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-col',{staticClass:"col-12 col-md-4"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('validation-provider',{attrs:{"name":_vm.$t('reservation_form.start_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.reservationStartDate,"outlined":"","error-messages":errors,"label":_vm.$t('reservation_form.start_date'),"append-icon":"event","readonly":""},on:{"click":function($event){_vm.formDateMenu = true},"click:append":function($event){_vm.formDateMenu = true}}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.formDateMenu),callback:function ($$v) {_vm.formDateMenu=$$v},expression:"formDateMenu"}},[_c('v-layout',{staticClass:"\n                  pa-8\n                  rounded-lg\n                  justify-center\n                  align-center\n                  flex-column\n                  white\n                "},[_c('vc-date-picker',{staticClass:"n-date-picker flex shrink",attrs:{"min-date":new Date(),"locale":_vm.$i18n.locale,"columns":_vm.$vuetify.breakpoint.smAndDown ? 1 : 2,"masks":{
                    input: 'YYYY-MM-DD',
                    data: 'YYYY-MM-DD',
                  },"attributes":_vm.calendarAttrs,"disabled-dates":_vm.disabledDates,"is-range":"","is-required":"","step":1,"color":"success","mode":"date"},on:{"input":function($event){_vm.formDateMenu = false}},model:{value:(_vm.formDate),callback:function ($$v) {_vm.formDate=$$v},expression:"formDate"}}),_c('v-row',{staticClass:"mt-8 primary--text font-weight-bold",attrs:{"align":"center","no-gutters":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-col',{staticClass:"col-12 col-md-auto shrink d-flex align-center mb-2"},[_c('div',{staticClass:"rounded-circle shrink",staticStyle:{"width":"24px","height":"24px","background":"#a8c92c"}}),_c('div',{staticClass:"ml-2 mr-4"},[_vm._v(" "+_vm._s(_vm.$t("reservation_form.calendar_legend.selected"))+" ")])]),_c('v-col',{staticClass:"col-12 col-md-auto shrink d-flex align-center mb-2"},[_c('div',{staticClass:"rounded-circle shrink",staticStyle:{"width":"24px","height":"24px","background":"#2980b9"}}),_c('div',{staticClass:"ml-2 mr-4"},[_vm._v(" "+_vm._s(_vm.$t("reservation_form.calendar_legend.reserved"))+" ")])]),_c('v-col',{staticClass:"col-12 col-md-auto shrink d-flex align-center mb-2"},[_c('div',{staticClass:"rounded-circle shrink",staticStyle:{"width":"24px","height":"24px","background":"#e9f2f8"}}),_c('div',{staticClass:"ml-2 mr-4"},[_vm._v(" "+_vm._s(_vm.$t("reservation_form.calendar_legend.pending"))+" ")])])],1)],1)],1)],1),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('validation-provider',{attrs:{"name":_vm.$t('reservation_form.end_date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-text-field',{ref:"test",attrs:{"value":_vm.reservationEndDate,"outlined":"","error-messages":errors,"label":_vm.$t('reservation_form.end_date'),"append-icon":"event","readonly":""},on:{"click":function($event){_vm.formDateMenu = true},"click:append":function($event){_vm.formDateMenu = true}}})]}}],null,true)})],1),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('v-select',{attrs:{"outlined":"","label":_vm.$t('reservation_form.guests'),"items":[1, 2, 3, 4],"type":"number"},model:{value:(_vm.formGuests),callback:function ($$v) {_vm.formGuests=$$v},expression:"formGuests"}})],1)],1),_c('v-row',{attrs:{"no-gutters":_vm.$vuetify.breakpoint.smAndDown}},[_c('v-col',[_c('v-textarea',{attrs:{"outlined":"","label":_vm.$t('reservation_form.message')},model:{value:(_vm.formMessage),callback:function ($$v) {_vm.formMessage=$$v},expression:"formMessage"}})],1)],1),_c('validation-provider',{attrs:{"rules":"required","name":"checkbox"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mb-2",attrs:{"error-messages":errors,"required":"","value":"1"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"font-weight-bold"},[_c('i18n',{attrs:{"path":"reservation_form.confirmation","tag":"div"},scopedSlots:_vm._u([{key:"storno",fn:function(){return [_c('a',{attrs:{"href":"/download/Podminky%20storno.pdf","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.$t("reservation_form.confirmation_storno")))])]},proxy:true},{key:"instructions",fn:function(){return [_c('a',{attrs:{"href":"/download/Bezpecnostni%20instrukce.pdf","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.$t("reservation_form.confirmation_instructions")))])]},proxy:true},{key:"gdpr",fn:function(){return [_c('a',{attrs:{"href":"/download/Souhlas%20se%20zpracovanim%20osobnich%20udaju.pdf","target":"_blank"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(_vm._s(_vm.$t("reservation_form.confirmation_gdpr")))])]},proxy:true}],null,true)})],1)]},proxy:true}],null,true),model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}})]}}],null,true)}),(_vm.errorMessage)?_c('v-alert',{staticClass:"text-left",attrs:{"dense":"","text":"","type":"error"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","depressed":"","rounded":"","large":"","type":"submit","loading":_vm.buttonLoading}},[_vm._v(_vm._s(_vm.$t("reservation_form.submit")))])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }