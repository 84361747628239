


































import { Component, Vue } from "vue-property-decorator";
import BookingCalendar from "@/components/BookingCalendar.vue";
import ReservationForm from "@/components/ReservationForm.vue";
import PriceList from "@/components/PriceList.vue";

@Component({
  components: { PriceList, ReservationForm, BookingCalendar },
})
export default class Reservation extends Vue {}
